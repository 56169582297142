import React, { useState } from "react";
import JapanPostalCode from "japan-postal-code";
import HeaderBox from "../../components/HeaderBox";
import Loading from "../../components/Loading";
import { Wrapper, Content, Row, MLabel, InputText, Line, InputDate, TextArea, Button, Footer, InputNumber } from './styles'
import { useSiteNew } from "./useSiteNew";

const dNow = new Date();
const dNowStr = `${dNow.getFullYear()}-${(dNow.getMonth() + 1).toString().padStart(2, '0')}-${dNow.getDate().toString().padStart(2, '0')}`;

const SiteNew = () => {
    const [siteName, setSiteName] = useState('');
    const [siteKana, setSiteKana] = useState('');
    const [dateBeginning, setDateBeginning] = useState(dNowStr);
    const [dateFinished, setDateFinished] = useState(dNowStr);
    const [postalCode, setPostalCode] = useState('');
    const [siteAddr, setSiteAddr] = useState('');
    const [tel, setTel] = useState('');
    const [area, setArea] = useState(0);
    const [siteMemo, setSiteMemo] = useState('');

    const { setSiteInfo, isLoading } = useSiteNew();

    const validate = () => {
        let msg = '';
        const dateStart = new Date(dateBeginning);
        const dateEnd = new Date(dateFinished);
        //SiteName
        if (!siteName.trim()) {
            msg = '現場名は入力必須項目です。';
        } else if (dateStart >= dateEnd) {
            msg = '期間指定開始日または最後日の日付範囲が正しくありません';
        }

        if (msg) {
            alert(msg);
            return false;
        }
        return true;
    }


    const handlePostCode = () => {
        if (!postalCode) return;
        JapanPostalCode.get(postalCode, (addr) => {

            setSiteAddr(`${addr.prefecture} ${addr.city}${addr.area}`);
        })

    }

    const handleRegister = () => {
        if (validate()) {
            setSiteInfo({
                siteName,
                siteKana,
                dateBeginning,
                dateFinished,
                postalCode,
                siteAddr,
                tel,
                area,
                siteMemo,
            })
        }
    }


    return (
        <Wrapper>
            <Loading isLoading={isLoading} />
            <HeaderBox title="現場登録" haveCart={false} />
            <Content>
                <Row column>
                    <MLabel>現場名：</MLabel>
                    <InputText
                        maxLength={100}
                        textHolder={'現場名'}
                        value={siteName}
                        onChange={(e) => { setSiteName(e.target.value) }}
                    />
                </Row>
                <Row column>
                    <MLabel>カナ：</MLabel>
                    <InputText
                        maxLength={100}
                        textHolder={'カナ'}
                        value={siteKana}
                        onChange={(e) => { setSiteKana(e.target.value) }}
                    />

                </Row>
                <Row column>
                    <MLabel>開始日～最後日：</MLabel>
                    <Line>
                        <InputDate
                            min={dNowStr}
                            max={'2099-12-31'}
                            value={dateBeginning}
                            onChange={(e) => { setDateBeginning(e.target.value); }}
                        />
                        ～
                        <InputDate
                            min={dNowStr}
                            max={'2099-12-31'}
                            value={dateFinished}
                            onChange={(e) => { setDateFinished(e.target.value); }}
                        />
                    </Line>
                </Row>
                <Row column>
                    <MLabel>郵便番号：</MLabel>
                    <Line>
                        <InputText
                            width={'100px'}
                            maxLength={10}
                            value={postalCode}
                            onChange={(e) => { setPostalCode(e.target.value); }}
                        />
                        <Button onClick={handlePostCode}>住所入力</Button>
                    </Line>
                </Row>
                <Row column>
                    <MLabel>住所：</MLabel>
                    <TextArea
                        maxLength={200}
                        rows={2}
                        value={siteAddr}
                        onChange={(e) => { setSiteAddr(e.target.value); }}
                    />
                </Row>
                <Row column>
                    <MLabel>ＴＥＬ：</MLabel>
                    <InputText
                        width={'150px'}
                        maxLength={50}
                        value={tel}
                        onChange={(e) => { setTel(e.target.value); }}
                    />
                </Row>
                <Row column>
                    <MLabel>面積：</MLabel>
                    <InputNumber
                        width={'100px'}
                        maxLength={10}
                        value={area}
                        onChange={(e) => { setArea(e.target.value); }}
                    />
                </Row>
                <Row column>
                    <MLabel>メモ(現場詳細、配達の際の注意事項があればご記入下さい)：</MLabel>
                    <TextArea
                        maxLength={300}
                        rows={4}
                        value={siteMemo}
                        onChange={(e) => { setSiteMemo(e.target.value); }}
                    />
                </Row>
                <Footer>
                    <Button onClick={() => { handleRegister(); }}>登録</Button>
                </Footer>
            </Content>
        </Wrapper>
    )
}

export default SiteNew;