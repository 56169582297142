import { TextBox } from "components/TextBox"
import { TermInputDate, Title, WrapperTerm, LineTerm, Wrapper, BtnSearch, Overlay, WrapperIcon } from "./styles"
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from '@fortawesome/free-solid-svg-icons';



export const TermOrder = ({ setTermSearch }) => {
  const [siteName, setSiteName] = useState("");
  const [addr, setAddr] = useState("");
  const [dFrom, setDFrom] = useState("");
  const [dTo, setDTo] = useState("");
  const [show, setShow] = useState(false);


  const onHandleSearch = () => {
    const objTerm = { siteName, siteAddr: addr, dFrom, dTo };
    setTermSearch(preValue => ({ ...preValue, ...objTerm }));
    setShow(false);
  }

  return <>
    <WrapperIcon >
      <span style={{ padding: "4px 0 8px 20px" }} onClick={() => setShow(true)}>
        <FontAwesomeIcon icon={faFilter} />
      </span>
    </WrapperIcon>
    {show && (
      <>
        <Overlay onClick={() => setShow(false)} />
        <Wrapper>
          <Title >検索条件</Title>
          <WrapperTerm>
            <LineTerm>
              <label htmlFor="id_site_name" style={{ paddingTop: "4px" }}>現場名：</label>
              <div style={{ border: "solid 1px #d3d3d3d3", borderRadius: "8px", margin: "0 8px" }}>
                <TextBox
                  id="id_site_name"
                  enterKeyHint='search'
                  placeholder={"現場名"}
                  maxLength={30}
                  value={siteName}
                  onChange={(e) => { setSiteName(e.target.value); }}
                  onClear={() => setSiteName("")}
                />
              </div>
            </LineTerm>
            <LineTerm>
              <label htmlFor="id_site_addr" style={{ paddingTop: "4px" }}>住所：</label>
              <div style={{ border: "solid 1px #d3d3d3d3", borderRadius: "8px", margin: "0 8px" }}>
                <TextBox
                  id="id_site_addr"
                  enterKeyHint='search'
                  placeholder={"住所"}
                  maxLength={30}
                  value={addr}
                  onChange={(e) => { setAddr(e.target.value); }}
                  onClear={() => setAddr("")}
                />
              </div>
            </LineTerm>
            <LineTerm>
              <label htmlFor="id_site_addr" style={{ paddingTop: "4px" }}>注文期間：</label>
              <div style={{ margin: "0 8px" }}>
                <TermInputDate
                  value={dFrom}
                  onChange={(e) => {
                    setDFrom(e.target.value);
                  }}
                />
                ～<TermInputDate
                  value={dTo}
                  onChange={(e) => {
                    setDTo(e.target.value);
                  }} />
              </div>
            </LineTerm>
          </WrapperTerm>
          <div style={{ display: "flex", justifyContent: "center", position: "fixed", bottom: "0", right: "0", left: "60px" }}>
            <BtnSearch onClick={onHandleSearch}>検索</BtnSearch>
          </div>
        </Wrapper>
      </>
    )}
  </ >
}
