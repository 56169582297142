import styled from 'styled-components';

export const FormSearchSub = styled.form`
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const FavoriteWrapper = styled.div`
    margin-top: 8px;
    padding-right:8px;
    display: flex;
    justify-content:space-between;
    align-items:center;
    input {
        margin-left: 12px;        
    }
`;