import React from "react";
import { useGoods } from "./useGoods";
import { Content, Wrapper } from "./styles";
import Loading from "components/Loading";
import HeaderBox from "components/HeaderBox";
import SearchBox from "components/SearchBox";
import { TermGoods } from "components/TermSearch";
import GoodsDetails from "./GoodsDetails";
import GoodsItem from "./GoodsItem";
import DialogAddGoods from "components/DialogAddGoods";
import { GoodsSub } from "./GoodsSub";

export const Goods = ({ all }) => {
  const { title,
    refTop,
    setTermSearch,
    goodsList,
    isLoading,
    goods,
    goodsIDSelected,
    setGoodsIDSelected,
    goodsAdd,
    hanleSelectGoods,
    handleChangeFavorite,
    handleAddToCart,
    handleCloseAddToCart,
    handleBack, setIsLoading } = useGoods(all);

  return (
    <Wrapper>
      <Loading isLoading={isLoading} />
      <HeaderBox title={title} haveCart={true} />
      <div style={{ position: "relative" }}>
        <SearchBox setTermSearch={setTermSearch} placeholder="商品名">
          <TermGoods setTermSearch={setTermSearch} all={all} />
        </SearchBox>
        <Content ref={refTop}>
          {!!goodsList?.length && (
            goodsList.map((goods, index) =>
              <GoodsItem key={`${index}${(new Date()).getTime()}_${goods.GoodsID}}`}
                goods={goods}
                handleSelect={hanleSelectGoods}
                handleChangeFavorite={handleChangeFavorite}
                handleAddToCart={handleAddToCart} />
            ))}
        </Content>
        {!!goods?.GoodsID && (
          <GoodsDetails goods={goods}
            handleChangeFavorite={handleChangeFavorite}
            handleAddToCart={handleAddToCart}
            handleBack={handleBack} />
        )}
      </div>
      {goodsIDSelected > 1 && (<GoodsSub goodsID={goodsIDSelected} setIsLoading={setIsLoading}
        hanleSelectGoods={hanleSelectGoods}
        handleChangeFavorite={handleChangeFavorite}
        handleAddToCart={handleAddToCart}
        handleClose={() => { setGoodsIDSelected(0) }}
      />
      )}
      <DialogAddGoods goods={goodsAdd}
        handleClose={handleCloseAddToCart} />
    </Wrapper>);
};
