import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
    Button,
    Content,
    Favorite,
    GoodsImg,
    Line,
    MLabel,
    RightControl,
    Title,
    Wrapper,
    WrapperContent,
} from './styles'

import GoodsImgDefault from "assets/images/goods_default.jpg";

const GoodsItem = ({ goods, handleSelect, handleChangeFavorite, handleAddToCart, isSub }) => {

    return (
        <Wrapper >
            <WrapperContent onClick={() => { if (!isSub) handleSelect(goods.GoodsID); }} style={{ pointerEvents: !!isSub ? "auto" : "none" }}>
                {!!isSub ? (<></>) : (
                    <div>
                        <GoodsImg src={goods?.GoodsImageUrl ? goods?.GoodsImageUrl : GoodsImgDefault} alt="image" />
                    </div>)}
                <Content>
                    <Title>{goods.GoodsName || '-'}</Title>
                    <Line>
                        <MLabel>サイズ：</MLabel>
                        {goods.GoodsSize || '―'}
                    </Line>
                </Content>
            </WrapperContent>
            <RightControl>
                <Favorite isFavorite={goods.FavoriteID > 0}
                    onClick={() => { handleChangeFavorite(goods.GoodsID, goods.GoodsSubID || 1, goods.FavoriteID) }}>
                    <FontAwesomeIcon icon={faStar} />
                </Favorite>
                <Button onClick={() => { handleAddToCart(goods); }}>選択</Button>
            </RightControl>
        </Wrapper>
    )
}

export default GoodsItem;