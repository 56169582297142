import styled from 'styled-components';

export const Line = styled.div`
`

export const MLabel = styled.small`
    color: #6c757d;
`

export const Dialog = styled.div`
position: fixed;
top: 50%;
left: 50%;    
transform: translate(-50%, -50%);
background-color: var(--lightGreen);
border: 1px solid rgba(112, 76, 182, 0.1);
border-radius: 10px;
width: 75vw;    
max-width: 500px;
z-index: 100;

`;

export const DialogTitle = styled.div`    
font-size: 1rem;
font-weight: bold;
display: flex;
justify-content: space-between;
align-items: center;
background-color: #00952750;
border-radius: 10px 10px 0 0;
border-bottom: 1px solid rgba(112, 76, 182, 0.3);
`;

export const TextTile = styled.div`
margin: 6px 10px;
`;

export const BtnClose = styled.button`
padding: 6px 15px;
cursor: pointer;
border: none;
outline: none;
background: none;
font-size: 1.25rem;
font-weight: bold;
border-radius: 10px;
:active {
    background-color: green;
    color: red;
}
`;

export const DialogContent = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-bottom: 20px;
`;

export const TextContent = styled.div`    
width: 100%;
padding: 5px 10px;
`;

export const TextInputWrapper = styled.div`    
width: 100%;
text-align: center;
padding: 10px 0 0 0;
`;

export const TextInput = styled.input.attrs({ type: 'text' })`
width: 80%;
font-size: 1rem;
padding: 3px 5px;
border: 1px solid grey;
border-radius: 3px;
color: #000;
`;

export const WrapperQuantity = styled.div`
width: 50%;
display: flex;
justify-content: space-between;
align-items: center;
margin-top: 10px; 
margin-bottom: 10px; 

`;

export const BtnQuantity = styled.button`    
appearance: none;
font-size: 1.2rem;
font-weight: bold;
text-align: center;
border: none;
background: none;
padding: 5px 13px;
outline: none;
border: 2px solid transparent;
color: blue;
cursor: pointer;
background-color: rgba(112, 76, 182, 0.1);
border-radius: 2px;
transition: all 0.15s;
`

export const Quantity = styled.span`
 padding: 0 5px;
 font-size: 1.2rem;
 font-weight: bold;
`

export const ButtonSelect = styled.button`
width: 50%;
padding: 8px;
font-size: 1rem;
outline: none;
border:1px solid transparent;
border-color: var(--morienGreen);
border-radius: 3px;
background-color: var(--morienGreen);
color: var(--white);
cursor: pointer;
:active{
    background-color: #32cd32;            
}
`

export const Overlay = styled.div`
position: fixed;
opacity: 1;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgba(0, 0, 0, 0.5);
pointer-events: all;
z-index: 99;
`;