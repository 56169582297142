import React, { useEffect, useState } from "react";
import { BtnClose, Content, Overlay, Wrapper, WrapperTitle } from "./styles";
import SearchBox from "components/SearchBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import goodsApi from "api/goodsApi";
import GoodsItem from "../GoodsItem";


export const GoodsSub = ({
  goodsID, setIsLoading,
  hanleSelectGoods,
  handleAddToCart, handleClose }) => {

  const [termSearch, setTermSearch] = useState({});
  const [goodsSubList, setGoodsSubList] = useState([]);

  const handleChangeFavoriteSub = async (goodsID, goodsSubID, favoriteID) => {
    try {
      setIsLoading(true);
      /**Call Api */
      const { data } = await goodsApi.updateGoodsFavorite({
        goodsID: goodsID,
        goodsSubID: goodsSubID || 1,
        favoriteID: favoriteID || '',
      });
      const params = {
        goodsID: goodsID,
        txtSearch: termSearch?.txtSearch || ''
      }
      const dtResponse = await goodsApi.searchGoodsSub(params);
      const dtGoodsSub = dtResponse?.data['dtGoodsSub'] ?? [];
      setGoodsSubList([...dtGoodsSub]);
    } catch (err) {
      alert(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async function getGoodsSubList() {
      if (goodsID < 2) return;
      try {
        setIsLoading(true);
        const params = {
          goodsID,
          txtSearch: termSearch?.txtSearch || ''
        }
        const dtResponse = await goodsApi.searchGoodsSub(params);
        const dtGoodsSub = dtResponse?.data['dtGoodsSub'] ?? [];

        setGoodsSubList([...dtGoodsSub]);
        // if (refTop) refTop.current.scrollTo(0, 0);
      } catch (error) {
        console.log(error);
        alert(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [goodsID, termSearch, setTermSearch, setIsLoading]);


  return (
    <>
      <Overlay />
      <Wrapper>
        <WrapperTitle>
          <div style={{ margin: "6px 8px" }}>商品選択</div>
          <BtnClose onClick={() => { handleClose(); }}><FontAwesomeIcon icon={faXmark} /></BtnClose>
        </WrapperTitle>
        <div style={{ position: "relative" }}>
          <SearchBox setTermSearch={setTermSearch} placeholder="商品名" />
          <Content >
            {!!goodsSubList?.length && (
              goodsSubList.map((goods, index) =>
                <GoodsItem key={`${index}${(new Date()).getTime()}_${goods.GoodsID}_${goods.GoodsSubID}`}
                  goods={goods}
                  handleSelect={hanleSelectGoods}
                  handleChangeFavorite={handleChangeFavoriteSub}
                  handleAddToCart={handleAddToCart}
                  isSub={true} />
              ))}
          </Content>
        </div>
      </Wrapper>
    </>);
};
