import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    display:flex;
    flex-direction: column;
    background-color:beige;
    padding: 8px 0 4px 0;
`;

export const FormSearch = styled.form`
    width: 100%;
    display:flex;
    flex-direction: column;     
    align-items: center;
`;
export const WrapperInput = styled.div`
    width: 90%;    
    max-width: 500px;    
    position: relative;  
    border-radius: 8px;    
    border: solid 1px #d3d3d3d3;
    overflow: hidden;  
    padding-left: 30px;
    background-color: #fff;
`;

export const SearchIcon = styled.div`
    position: absolute;
    top: 0; 
    left: 0; 
    bottom: 0;
    padding: 0 8px;
    background: #a3a3a3;
    display: flex;
    justify-content: center;
    align-items: center;
`;