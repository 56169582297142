import styled from 'styled-components';

export const Overlay = styled.div`
    position: fixed;
    opacity: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    pointer-events: all;
    z-index: 9;
`;

export const Wrapper = styled.div`
    position: fixed;
    top: 40px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: var(--lightGreen);
    border: 1px solid rgba(112, 76, 182, 0.1);
    border-radius: 10px;
    width: 80vw;        
    max-width: 500px;
    z-index: 10;
`;

export const WrapperTitle = styled.div`    
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #00952750;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid rgba(112, 76, 182, 0.3);
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom:150px;
    max-height: 75vh;
    overflow-y: scroll;
`;



export const BtnClose = styled.button`
    padding: 6px 15px;
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    font-size: 1.25rem;
    font-weight: bold;
    border-radius: 10px;
    :active {
        background-color: green;
        color: red;
    }
`;

